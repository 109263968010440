import Events from './components/events';

jQuery( document ).ready( function ( $ ) {
	Events();

	// Newsletter
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		var data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit/',
			dataType: 'json',
			data: data,
			success: function ( data ) {
				$( 'form' ).replaceWith( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				console.log( err );
			}
		} );
	} );
} );