import momemt from 'moment';

export default function Events() {

	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/A.Chal/events?app_id=45PRESS',
		method: 'GET',
		dataType: 'json',
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					const location = event.venue.city + ', ' + event.venue.region;
					html += '<div data-bit-id="' + event.id + '">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'ddd, MMM D' ) + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-links">';
					html += '<a href="' + event.url + '" target="_blank" class="btn btn-rsvp">RSVP</a>';
					for ( let offer of event.offers ) {
						if ( offer.type === 'Tickets' ) {
							html += '<a href="' + offer.url + '" target="_blank" class="btn btn-tickets">' + offer.type + '</a>';
						}
					}

					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( '<div class="no-events">No upcoming events.</div>' );
			}
		}
	} );
}